import { Court } from "@/models/club/court";
import ClubEquipmentTypeEnum from '@/utils/enum/club-equipment-type-enum';

export class ClubEquipment {

    constructor(data) {
        this.id = data.id;
        this.club = data && data.club ? data.club : undefined;
		this.name = data && data.name ? data.name : "";
        this.cost = data && data.cost != null ? data.cost.toString()  : "";
        this.member_cost = data && data.member_cost != null ? data.member_cost.toString()  : "";
        this.quantity = data && data.quantity ? data.quantity.toString()  : "";
        this.book_by_member = data && data.book_by_member ? data.book_by_member : false;
        this.min_duration_member = data && data.min_duration_member !== undefined ? data.min_duration_member : 30;
        this.book_by_nonmember = data && data.book_by_nonmember ? data.book_by_nonmember : false;
        this.min_duration_nonmember = data && data.min_duration_nonmember !== undefined ? data.min_duration_nonmember : 30;
        this.ttype = data && data.ttype ? data.ttype : "";
        this.notes = data && data.notes ? data.notes : "";

        this.court_set = [];
        if (data && data.court_set) {
            this.court_set = data.court_set.map((item) => new Court(item));
        }
    }

    get type_full() {
        return ClubEquipmentTypeEnum[this.ttype];
    }
}